// Adding paypal-sans as font. I remember I've seen other places adding that, I should check it out in the future
@import '~paypal-components/atoms/typography/less/paypal-sans';

// Importing just the important stuff from this framework, it's basically a "paypal's wrapper" of bootstrap,
// however we don't actually need everything coming from it.
// This is specifically used by Tesla, if they change their css needs we could remove these imports
@import '~frontend-framework-paypal/dist/twbs-light/less/variables';
@import '~frontend-framework-paypal/dist/twbs-light/less/mixins';
@import '~frontend-framework-paypal/dist/twbs-light/less/grid.less';

// This is supposed to be deprecated now in favor of pp-vx, we should address that in the future
@import (inline) '~vxpattern-lib-paypal/dist/vx-lib.css';

/* ratelimiter 3.0 */
@import '~adsinterceptor/src/less/captcha';

/*rtl:begin:ignore*/
[dir='rtl'] .onboardingCreditCardNum {
  right: inherit;
}

[dir='rtl'] .vx_select:after {
  right: inherit;
}
/*rtl:end:ignore*/
